export const NAV_GLOBAL = [
    {
        title: 'Travel eSIMs',
        url: '/travel-esims/',
        children: null,
        secondary: null
    },
    {
        title: 'WTF is eSIM?',
        url: '/wtf-is-esim/',
        children: null,
        secondary: null
    },
    {
        title: 'eSIM compatibility',
        url: '/esim-compatibility-checker/',
        children: null,
        secondary: null
    },
    {
        title: 'ZenSim app',
        url: '/zensim-app/',
        children: null,
        secondary: null
    },
    {
        title: 'About us',
        url: '/about-us/',
        children: null,
        secondary: null
    },
    {
        title: 'Contact us',
        url: '/contact/',
        children: null,
        secondary: null
    }
]

export const NAV_AUSTRALIA = [
    {
        title: 'Home',
        url: '/',
        children: null,
        secondary: null
    },
    {
        title: 'Mobile',
        url: '/mobile-phone-plans/',
        children: {
            title: 'Plans',
            items: [
                { title: 'Monthly mobile plans', url: '/mobile-phone-plans/', icon: 'nav-esim-plans.svg' },
                { title: 'Travel eSIMs', url: '/travel-esims/', icon: 'nav-travel-plans.svg' },
                { title: 'Unlimited data plans', url: '/unlimited-data-mobile-plans/', icon: 'shaka.svg' }
            ]
        },
        secondary: {
            title: 'Info',
            items: [
                { title: 'Coverage', url: '/coverage/', icon: 'nav-coverage.svg' },
                { title: 'Our network', url: '/our-network/', icon: 'reliable.svg' },
                { title: 'International roaming', url: '/roaming/', icon: 'nav-roaming.svg' },
                { title: 'FAQs', url: '/faqs/', icon: 'nav-faq.svg' },
                { title: 'Check eSIM compatibility', url: '/esim-compatibility-checker/', icon: 'esim.svg' }
            ]
        }
    },
    {
        title: 'WTF is eSIM?',
        url: '/wtf-is-esim/',
        children: null,
        secondary: null
    },
    {
        title: 'About us',
        url: '/about-us/',
        children: null,
        secondary: null
    },
    {
        title: 'Squad Savings',
        url: '/squad-savings/',
        children: null,
        secondary: null
    },
    {
        title: 'Contact us',
        url: '/contact/',
        children: null,
        secondary: null
    }
]

export const COLOR_ELECTRIC = 'electric'
export const COLOR_LED = 'led'
export const COMPATIBILITY_CHECKER = '{{COMPATIBILITY_CHECKER}}'
export const UNCAPPED_SPEEDS = '{{UNCAPPED_SPEEDS}}'
export const SUSTAINABILITY = '{{SUSTAINABILITY}}'
export const SQUAD_SAVINGS = '{{SQUAD_SAVINGS}}'
export const ABOUT_US = '{{ABOUT_US}}'
export const VARIANT_BLOG = 'blog'
export const VARIANT_LANDING = 'content'

export const PROMO_ONCE_OFF = 'once-off'
export const PROMO_MONTHLY = 'monthly'
export const PROMO_SQUAD = 'squad-savings'

export const LANG_EN = 'en'
export const LANG_ES = 'es'
export const LANG_PT = 'pt'
export const LANG_ZH = 'zh'
export const LANG_VI = 'vi'

export const MODAL_CONTACT = 'contact'
export const MODAL_ESIM_CHECKER = 'esim-checker'
export const MODAL_DATABANK = 'databank'
export const MODAL_NETWORK = 'network'
export const MODAL_INTL_CALLS = 'intl-calling'
export const MODAL_30_DAY = '30-day-expiry'
export const MODAL_SUSTAINABILITY = 'sustainability'
export const MODAL_SQUAD_SAVINGS = 'squad-savings'
export const MODAL_ABOUT_US = 'about-us'
export const MODAL_PLAN_TYPE = 'plan-type'
export const MODAL_ENDLESS_DATA = 'endless'
export const MODAL_REDIRECT = 'redirect'
export const MODAL_COOKIE_CONSENT = 'cookie-consent'
export const MODAL_UNCAPPED = 'uncapped'

export const CATEGORY_MOBILE = 'mobile'
export const CATEGORY_TRAVEL = 'travel'

export const CURRENCY_AUD = 'aud'
export const CURRENCY_NZD = 'nzd'
export const CURRENCY_USD = 'usd'
// todo: Sesrch for the below and remove unneeded code
export const ALLOWED_CURRENCIES = [CURRENCY_AUD, CURRENCY_NZD, CURRENCY_USD]

export const LOCALE_AU = 'AU'
export const LOCALE_NZ = 'NZ'
export const LOCALE_US = 'US'

export const CONSENT_COOKIE_NAME = 'gatsby-gdpr-consent'

export const GDPR_COUNTRIES = new Set([
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FI', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'HU', // Hungary
    'IS', // Iceland
    'IE', // Ireland
    'IT', // Italy
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'NL', // Netherlands
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'GB', // United Kingdom (UK-GDPR post-Brexit)
    'CH', // Switzerland (aligned with GDPR)
    'BR', // Brazil (LGPD)
    'CA', // Canada (PIPEDA, Quebec’s Law 25)
    'US' // California (CCPA, CPRA)
])
